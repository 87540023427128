// import SVGInjector from 'svg-injector'
import SmoothScroll from 'smooth-scroll'
import Glide from '@glidejs/glide'
import GLightbox from 'glightbox'
// import simpleParallax from 'simple-parallax-js';

//svg injection
// const mySVGsToInject = document.querySelectorAll('svg[data-src]');
// SVGInjector(mySVGsToInject)

window.onload = new function(){
	// var backobjs = document.getElementsByClassName('backobj__item');
	// for(let i = 0; i < backobjs.length; i++){
	// 	new simpleParallax(backobjs[i], {
	// 		overflow: true,
	// 		scale: 1.5
	// 	})
	// }

	new SmoothScroll('a[href*="#"]', {
		speed: 1000
	})

	// menu menu
	const header = document.getElementById('header')
	const menu = document.getElementById('menu')
	if(menu && header){
		menu.addEventListener('click', function(){
			header.classList.toggle('header--mobile-active')
		})
	}
	const closemenu = document.getElementById('closemenu')
	if(closemenu && header){
		closemenu.addEventListener('click', function(){
			header.classList.toggle('header--mobile-active')
		})
	}

	window.addEventListener("scroll", () => {
		if (window.scrollY > 100) {
			header.classList.add('header-padding')
		} else {
			header.classList.remove('header-padding')
		}
	});
	





	// services menu
	//const languageselectdropdowns = document.querySelectorAll('.language-select');
	//if(languageselectdropdowns && languageselectdropdowns.length > 0){
	//	for(let i = 0; i < languageselectdropdowns.length; i++){
	//		languageselectdropdowns[i].addEventListener('click', function(e){
	//			e.preventDefault()
	//			document.querySelectorAll('.language-select__dropdown')[i].classList.toggle('language-select__dropdown--active')
	//		})
	//	}
	//}

	const dots = document.querySelectorAll('.dot')
	const dotinfos = document.querySelectorAll('.dot-info')

	for(let i = 0; i < dots.length; i++){
		dots[i].addEventListener('click', function(e){
			var screenwidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			if(screenwidth >= 1091){
				e.preventDefault()
				dotinfos[i].classList.toggle('dot-info--active');
				dotinfos[i].addEventListener('click', function(e){
					window.location.href = dots[i].href;
				})
			}
		})
	}

	//lightbox


	const realisationslider = document.getElementById('realisation-slider');

	if(realisationslider){
		var glide = new Glide('#realisation-slider', {
			type: 'carousel',
			perView: 4,
			focusAt: 1,
			gap: 0,
			breakpoints: {
				800: {
					perView: 2
				},
				480: {
					perView: 1
				}
			}
		})
		glide.on('mount.after', e => {
			console.warn('initated')
			const lightbox = GLightbox({
				selector: '.lightbox',
				touchNavigation: true,
				type: 'image',
				loop: true,
			});
		})
		glide.mount()
	}

	var accordions = document.getElementsByClassName("accordion");
	for (var i = 0; i < accordions.length; i++) {
		accordions[i].onclick = function() {
			this.classList.toggle('is-open');
			var content = this.nextElementSibling;
			if (content.style.maxHeight) {
				content.style.maxHeight = null;
			} else {
				content.style.maxHeight = "none";
			}
		}
	}


    // HOME HEADER IMG BG CHANGE
	const homeNavBttns = document.querySelectorAll('.js-home-nav-bttn');
	const homeImgs = document.querySelectorAll('.js-home-img');

	homeNavBttns.forEach($homeNavBttn => {
	    const homeNavBttnId = $homeNavBttn.dataset.nav_id;

	    $homeNavBttn.addEventListener('mouseover', function(){
	        homeImgs.forEach($homeImg => {
	            const homeImgId = $homeImg.dataset.img_id;

	            if (homeNavBttnId === homeImgId ) {
	                $homeImg.classList.add("show");
	            } else {
	                $homeImg.classList.remove("show");
	            }
	        });
	    })
	});

	// //herobannerslider
	// const homeslider = document.getElementsByClassName('home-overlay-slider__slider')
	// if(homeslider.length > 0){
	// 	console.log(homeslider)
	// 	var slides = document.querySelectorAll('.home-overlay-slider__slider .glide__slide')
	// 	if(slides.length > 1){
	// 		new Glide(homeslider[0], {
	// 			type: 'carousel',
	// 			animationDuration: 800,
	// 			animationTimingFunc: 'ease-in-out',
	// 			autoplay: 8000,
	// 		}).mount()
	// 	}else{
	// 		document.querySelector('.hero-banner__slider .glide__arrows').style.display = 'none';
	// 	}
	// }

	

	/*
	var lightboxtriggers = document.getElementsByClassName('glide__slides')
		for(let i = 0; i < lightboxtriggers.length; i++){
		lightboxtriggers[i].addEventListener('click', function(e){
			console.warn(i)
		})
	}
	var lightboxtriggers = document.getElementsByClassName('glide__slide--clone')
		for(let i = 0; i < lightboxtriggers.length; i++){
		lightboxtriggers[i].addEventListener('click', function(e){
			// lightbox.open()
			console.warn(i)
		})
	}
	*/

}